@import './constants';

$tab-height: 25px;
$side-tab-line-height: 30px;
$font-size-tab-option: $character-5 * 1px;

.tab-row {
    display: flex !important;
    height: $tab-height;
    line-height: 1em;
    margin: 25px 0 10px 0;
}

.tab-col {
    margin: 15px 0 0 0;
    padding: 0 !important;
}

.tab-body {
    margin: 0;
}

.tab-option {
    display: flex;
    float: left;
    white-space: nowrap;
    align-items: baseline; /* align vertical */
    font-family: $font-family;
    font-weight: 400;
    font-size: $font-size-tab-option;
    color: $color-9;
    cursor: pointer;
    height: $tab-height;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 0px !important;
    padding-right: 10px;
    padding-bottom: $padding * 1px;
}

.tab-navigation {
    display: flex;
    float: left;
    white-space: nowrap;
    align-items: baseline; /* align vertical */
    font-family: $font-family;
    font-weight: 400;
    font-size: $font-size-tab-option;
    color: $color-9;
    cursor: pointer;
    height: $tab-height;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 10px !important;
    padding-right: 10px;
    padding-bottom: $padding * 1px;
}

.tab-navigation:first-of-type {
    padding-left: 0px !important;
}

.tab-option:hover {
    font-weight: 600;
    color: $color-8;
}

.tab-option:active {
    font-weight: 600;
    color: $color-8;
}

.tab-option-selected {
    display: flex;
    float: left;
    white-space: nowrap;
    align-items: baseline; /* align vertical */
    font-family: $font-family;
    font-weight: 500;
    font-size: $font-size-tab-option;
    color: $color-7;
    cursor: pointer;
    height: $tab-height;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 0px !important;
    padding-right: 10px;
    padding-bottom: $padding * 1px;
    text-decoration: 1px $color-7 underline;
    text-underline-offset: 6px;
    -webkit-text-decoration-line:  underline;
    -webkit-text-decoration-color: $color-7;

}

.tab-navigation-selected {
    display: flex;
    float: left;
    white-space: nowrap;
    align-items: baseline; /* align vertical */
    font-family: $font-family;
    font-weight: 500;
    font-size: $font-size-tab-option;
    color: $color-7;
    cursor: pointer;
    height: $tab-height;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 10px !important;
    padding-right: 10px;
    padding-bottom: 0;
    text-decoration: 1px $color-7 underline;
    -webkit-text-decoration-line:  underline;
    -webkit-text-decoration-color: $color-7;
    text-underline-offset: 6px;
}

.tab-navigation-selected:first-of-type {
    padding-left: 0px !important;
    padding-bottom: 0;
    text-underline-offset: 6px;
    -webkit-text-decoration-line:  underline;
    -webkit-text-decoration-color: $color-7;

}

@media (min-width: 768px) and (max-width: 991.98px) {
    .tab-navigation {
        display: flex;
        float: left;
        white-space: inherit;
        align-items: baseline;
        font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
        font-weight: 400;
        font-size: 14px;
        color: $color-9;
        cursor: pointer;
        height: 25px;
        line-height: 1em;
        vertical-align: middle;
        padding-left: 10px !important;
        padding-right: 10px;
        padding-bottom: 15px;
    }

    .tab-navigation-selected {
        font-size: 14px;
        font-weight: 500;
        white-space: inherit;
        color: $color-7;
        text-decoration: 1px $color-7 underline;
        text-underline-offset: 4px;
    }

    .tab-option {
        display: flex;
        float: left;
        white-space: inherit;
        align-items: baseline;
        font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
        font-weight: 400;
        font-size: 14px;
        color: $color-9;
        cursor: pointer;
        height: 25px;
        line-height: 1em;
        vertical-align: middle;
        padding-left: 0px !important;
        padding-right: 10px;
        padding-bottom: 15px;
    }

    .tab-option-selected {
        display: flex;
        float: left;
        white-space: nowrap;
        align-items: baseline;
        font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
        font-weight: 500;
        font-size: 14px;
        color: $color-7;
        cursor: pointer;
        height: 25px;
        line-height: 1em;
        vertical-align: middle;
        padding-left: 0px !important;
        padding-right: 10px;
        padding-bottom: 15px;
        -webkit-text-decoration: 1px $color-7 underline;
        text-decoration: 1px $color-7 underline;
        text-underline-offset: 4px;
    }

}
