@import './constants';

$dashboard-button-height: 40px;
$dashboard-line-height: 30px;
$dashboard-button-width: 110px;
$dashboard-button-wide-width: 190px;
$dashboard-gear-width: 76px;
$workspace-button-font-size: $character-4 * 1px;

.workspace-wide {
    position: fixed;
    top: $menu-height;
    left: $menu-narrow-width;
    right: 0px;
    max-height: calc(100vh - 85px);
    height: 100%;
    padding: 25px;
    background-color: $color-background-light;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 0px 0px 50px;
}

.workspace-narrow {
    position: fixed;
    top: $menu-height;
    left: $menu-wide-width;
    right: 0px;
    max-height: calc(100vh - 85px);
    height: 100%;
    padding: 15px;
    background-color: $color-background-light;
    overflow-x: hidden;
    overflow-y: auto;
}

.blue {
    color: #478EB3;
}

.dashboard-icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.task-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.dashboard-header {
    font-family: $font-family;
    font-weight: 600;
    color: $color-2;
    font-size: $font-size-dashboard-title;
    float: left;
    backface-visibility: hidden;
    white-space: nowrap;
}

.dashboard-header span {
    padding-right: 15px;
    width: 100%;
}

.span-header {
    color: $color-2;
    font-size: $font-size-dashboard-title;
}

.section-header {
    margin-bottom: 10px;
    font-family: $font-family;
    font-weight: bolder;
    font-size: $character-7 * 1px;
    color: $color-10;
}

.inner-column {
    border-color: $color-2;
    border-style: none solid none none;
    border-width: 2px;
}

.full-width {
    width: 100%
}
