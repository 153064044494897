/*color styles*/
$color-transparent: rgba(0, 0, 0, 0);
$color-1: #ffffff;
$color-2: #023C5B;
$color-3: #F8F9F9;
$color-4: #CCD4D7;
$color-5: #A8B4BB;
$color-6: #C3C3C3;
$color-7: #666666;
$color-8: #448bb0;
$color-9: #478EB3;
$color-10: #364046;
$color-11: #A14046;
// $color-11-t: #A1404622;
$color-12: #022D44;
$color-13: #033C5A;
$color-14: rgba(0, 0, 0, .2);
$color-15: #5C6E78;
$color-16: #DCE1E4;
$color-17: rgba(0, 0, 0, .16);
$color-18: rgba(0, 0, 0, 0);
$color-title-light: $color-2;
$color-19: #71c4b6;
// $color-19-t: #71c4b622;
$color-20: #F0F0F0;
$color-21: #EEF1F4;
$color-22: #333333;

$color-background-dark: $color-12;
$color-background-dark-icon: $color-13;
$color-background-medium: $color-2;
$color-background-light: $color-20;
$color-background-white: $color-1;

$color-border-input-box: $color-4;
$color-border-input-box-active: $color-9;
$color-border-separator: $color-2;

$color-link-dark-bg: $color-1;
$color-link-hover-dark-bg: $color-5;
$color-link-active-dark-bg: $color-9;

$color-link-light-bg: $color-9;
$color-link-hover-light-bg: $color-5;
$color-link-active-light-bg: $color-1;

$color-link-medium: $color-6;
$color-link-medium-hover: $color-8;
$color-link-medium-active: $color-1;

$color-link-border-active: $color-8;

$color-error: $color-11;


/*character styles*/
$character-1: 8;
$character-2: 10;
$character-3: 12;
$character-4: 14;
$character-5: 16;
$character-6: 19;
$character-7: 21;
$character-8: 28;
$character-9: 32;
$character-10: 54;


$font-size-header-title: $character-6 * 1px;
$font-size-header-menu: $character-6 * 1px;
$font-size-blue-button: $character-3 * 1px;
$font-size-splash-link: $character-6 * 1px;
$font-size-splash-title: $character-9 * 1px;
$font-size-left-menu-icon: $character-7 * 1px;
$font-size-left-menu-link: $character-4 * 1px;
$font-size-dashboard-link: $character-7 * 1px;
$font-size-dashboard-value: $character-10 * 1px;
$font-size-dashboard-label: $character-3 * 1px;
$font-size-dashboard-icon: $character-8 * 1px;
$font-size-dashboard-title: $character-7 * 1px;
$font-size-placeholder: $character-3 * 1px;

$font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;

$border-separator-height: 2px;
$border-separator-thick-height: 4px;

/*menu width*/
$menu-size-1: 55;
$menu-size-2: 230;
$menu-size-3: 70;
$menu-size-4: 185;
$menu-size-5: 870;
$menu-size-6: 700;
$menu-size-7: 440;
$padding: 15;
$menu-narrow-width: $menu-size-1 * 1px;
$menu-wide-width: $menu-size-2 * 1px;
$menu-height: 85 * 1px;
$body-narrow-padding-width: $menu-size-2 + $padding * 1px;
$body-wide-padding-width: $menu-size-3 * 1px;
$dashboard-header-height: 42px;

/*dashboard dimensions*/
$dashboard-alert-height: $menu-size-4 * 1px;
$dashboard-info-height: $menu-size-5 * 1px;
$dashboard-list-height: $menu-size-7 * 1px;

/*legacy link colour definitions*/
$link-color-1: #2fffe7;
$link-color-2: #00e9cd;
$link-color-3: #485167;
$link-color-4: #00a381;

body {
    margin: 0px;
    background-color: $color-2;
}

.logo-login {
    width: 100%;
}

::placeholder {
    color: #666666;
    font-size: $font-size-placeholder;
}

.column-centered {
    display: block;
    margin: auto;
}

.centered {
    text-align: center;
}

.center-fields {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
}

.separator {
    color: $color-border-separator;
    height: $border-separator-height;
    line-height: $border-separator-height;
    margin-bottom: 10px;
}

.separator-thick {
    color: $color-border-separator;
    height: $border-separator-thick-height;
    line-height: $border-separator-thick-height;
}

.member-type-icon {
    height: 20px;
    position: relative;
    top: -2px;
}

.rotate {
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    transform: rotate(-90deg);
}

.overflow-wrap-any {
    overflow-wrap: anywhere;
}

a {
    font-size: $font-size-splash-link;
    font-weight: 500;
    color: $color-9;
}

a:hover {
    color: $color-8;
    text-decoration: none;
}

a:focus {
    color: $color-8;
}

a i {
    font-size: $font-size-left-menu-icon;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.dashboard-right {
    margin-right: -15px;
}

.tile-icon {
    width: 1.563rem;
    height: 1.563rem;
    margin-right: 1.438rem;
    margin-left: -5px;
    color: #478eb3;
}

.onboard-nav {
    min-width: 200px;
}

.left-col {
    min-width: 360px;
    display: block;
}

.icon {
    width:40px;
    height: 40px;
}

.smallIcon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.notesIcon {
    display: inline-block;
    vertical-align: top;
    width: 5em;
}

.workGadgetIcon {
    display: inline;
    vertical-align: top;
}

.taskName {
    display: inline;
    vertical-align: top;
}

.alignTop {
    vertical-align: top;
}

.inlineBlockTop {
    display: inline-block;
    vertical-align: top;
}

.fontAwesome {
    font-size:22px;
    color:green;
}

.form-title {
    font-weight: 600;
    color: #023C5B;
    font-size: 21px;
}

.subtitle {
    color: $color-22;
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
}

.error-message {
    text-align: center;
    color: #d93434;
    margin: auto;
    width: 80%;
}

.error {
    color: #d93434;
}

.priority, .negative-amount, .debit {
    color: #d93434;
}

.fa-group {
    font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
    padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
    height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
    padding: 0px 0px 0px 10px;
}
/* Tablet Responsive size */

@media (min-width: 768px) and (max-width: 991.98px) {
    body {
        font-size: 0.938rem;
    }

    a {
        font-size: $character-5 * 1px;
    }

    .form-title {
        font-size: $character-5 * 1px;
    }

    .logo-login {
        width: 70%;
    }

}

/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
    .logo-login {
        width: 70%;
    }

    .form-title {
        font-size: $character-5 * 1px;
    }

}
