/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
a:focus {
  outline: none !important;
}

.title-bold-font {
  font-weight: bold;
}

ol > li.bold-marker::marker {
  font-weight: bold;
}

ol.no-marker, ul.no-marker {
  list-style-type: none;
}

.required {
  color: #d93434;
}

/* modal styles */
.modal-close {
  float: right;
  font-size: 19px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  background-color: #448bb0;
  border-radius: 50%;
  padding: 2px 12px 5px 12px;
}

.normal-modal {
  width: 700px;
}

@media screen and (max-height: 799px) {
  .full-modal {
    width: 100% !important;
    height: 100% !important;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
}
@media screen and (min-height: 800px) {
  .full-modal {
    width: 80%;
    max-width: 80%;
    max-height: 90%;
    margin-top: 20px;
  }
}

i.close {
  cursor: default;
}

.mat-dialog-container {
  padding: 0px !important;
  height: auto !important;
  border-radius: 10px !important;
}

.mat-dialog-container.fixed {
  overflow: hidden;
}

.modal-header {
  display: inherit;
  padding: 20px;
  background-color: #F0F0F0;
}

.modal-content-small {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin: 0.625rem auto 1.25rem;
  width: 350px;
  border: none;
}

.modal-content-medium {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin: 0.625rem auto 1.25rem;
  width: 500px;
  border: none;
}

.modal-content {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border: none;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border: none !important;
  padding: 0 20px 20px 20px;
}

.modal-footer-login {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border: none !important;
  padding: 0 20px 20px 20px;
}

.modal-footer > * {
  margin: 0px;
}

.mat-bottom-sheet-container {
  font: inherit;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  .modal-header {
    display: inherit;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .modal-content-small {
    width: 100%;
  }
  .modal-header {
    display: inherit;
  }
  .modal-footer-login {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: none !important;
    padding: 0 20px 20px 20px;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
a:focus {
  outline: none !important;
}

.title-bold-font {
  font-weight: bold;
}

ol > li.bold-marker::marker {
  font-weight: bold;
}

ol.no-marker, ul.no-marker {
  list-style-type: none;
}

.required {
  color: #d93434;
}

.form-text-area-editable {
  width: 300px;
  height: 105px;
}

.form-input-editable {
  width: 100%;
  max-width: 500px;
  height: 35px;
}

.form-control.form-tel-input-narrow {
  display: inline-block;
  width: 50px;
  height: 35px;
  margin-right: 15px;
}

.form-control.form-tel-input-wide {
  display: inline-block;
  width: 100px;
  height: 35px;
}

.form-input-narrow-editable {
  width: 180px;
  height: 35px;
}

.form-input-table-header:first-of-type {
  margin-left: 0;
  margin-bottom: 10px;
}

.form-input-table-header {
  display: inline-block;
  width: auto;
  height: 35px;
  margin-left: 20px;
}

.form-label {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 600;
  color: #023C5B;
  font-size: 14px;
  margin: 0px;
}

.form-title {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 600;
  color: #666666;
  font-size: 21px;
}

.form-error {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  color: #A14046;
  font-size: 14px;
}

.search-text-input {
  display: inline;
  margin: 0 0 0 12px;
  line-height: 42px;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  background-color: #ffffff;
  border-style: none;
  font-size: 14px;
  position: absolute;
}

.icon {
  display: inline;
  line-height: 42px;
  color: #CCD4D7;
  font-size: 19px;
  margin: 0 10px 0 0;
  padding: 0;
  position: relative;
  top: 50%;
  backface-visibility: hidden;
  transform: translate3d(0, -50%, 0) scale(1, 1);
}

.search {
  display: inline-block !important;
  width: 250px;
  height: 40px;
  line-height: 42px;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #CCD4D7;
  padding: 0;
  vertical-align: middle;
}

.checkbox {
  width: 20px;
  height: 20px;
  border-color: #CCD4D7;
  border-style: solid;
  border-width: 1px;
  color: #478EB3;
  font-family: serif;
  font-size: 10px;
  margin-left: 15px;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-standard {
  float: left;
  clear: both;
  width: 600px;
}

.form-control {
  border-bottom: 1px solid #666666;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  appearance: auto;
  width: 100%;
  max-width: 500px;
}

.form-control-header {
  border-bottom: 1px solid #666666;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  appearance: auto;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
}

.form-control:focus {
  border: none;
  box-shadow: none;
}

input.form-control.ng-dirty:not(form):not(.searcher), textarea.form-control.ng-dirty:not(form), select.form-control.ng-dirty:not(form) {
  border-bottom: 1.5px solid #57ba06 !important;
  box-shadow: none;
}

input.form-control.ng-invalid:not(form):not(.searcher), textarea.form-control.ng-invalid:not(form), select.form-control.ng-invalid:not(form) {
  border-bottom: 1.5px solid #d93434 !important;
  box-shadow: none;
}

input.form-control-header:disabled, textarea.form-control-header:disabled, select.form-control-header:disabled {
  background-color: #e9ecef;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .form-title {
    font-size: 16;
  }
  .form-input-table-header {
    display: inline-block;
    width: auto;
    height: 35px;
    margin-left: 0;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .form-input-editable {
    width: 100%;
    height: 35px;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.dashboard-alert {
  padding: 15px;
  border-top-width: 4px;
  border-radius: 9px;
  /*border-top-style: solid;
  border-top-color: $color-dashboard-alert-top-border;*/
  background-color: #ffffff;
  margin-bottom: 30px;
}

.dashboard-alert a {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 21px;
  color: #478EB3;
  text-decoration: none;
}

.dashboard-alert-h2 {
  margin: 5px 0px 10px 0px;
  line-height: 28px;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 28px;
  font-weight: normal;
  color: #364046;
}

.dashboard-info-main-amount {
  margin: 5px 0px;
  line-height: 32px;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 32px;
  font-weight: normal;
  color: #364046;
}

.dashboard-info-sub-amount {
  margin: 5px 0px;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 19px;
  font-weight: normal;
  color: #364046;
}

.dashboard-alert label {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 12px;
  color: #023C5B;
}

.dashboard-alert-meta {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 12px;
  color: #666666;
}

.dashboard-alert-icon {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 28px;
  color: #666666;
}

.dashboard-info {
  padding: 20px 15px;
  border-top-width: 4px;
  border-radius: 9px;
  min-height: 275px;
  background-color: #ffffff;
  max-height: calc(100vh - 115px);
  overflow: auto;
}

.dashboard-list {
  padding: 15px;
  border-top-width: 4px;
  border-radius: 9px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.dashboard-list a {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 21px;
  color: #478EB3;
  text-decoration: none;
}

.dashboard-list-a:not([href]) {
  font-size: 12px !important;
  font-weight: 400;
  color: #478EB3;
  text-decoration: none;
  cursor: pointer;
}

.dashboard-list h1 {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 14px;
  color: #364046;
}

.dashboard-list label {
  position: relative;
  top: -10px;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 14px;
  color: #666666;
}

.dashboard-info a, dashboard-link a {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 21px;
  color: #478EB3;
  text-decoration: none;
}

.dashboard-info a:hover, dashboard-link a:hover {
  cursor: pointer;
}

.dashboard-info h1 {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 54px;
  color: #364046;
}

.dashboard-info label {
  position: relative;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 12px;
  color: #023C5B;
}

.dashboard-info-icon {
  display: inline-block;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 28px;
  color: #666666;
  margin-right: 15px;
}

.dashboard-list-summary {
  float: right;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-size: 12px;
  color: #666666;
  vertical-align: bottom;
  text-align: end;
}

.external-system-registration {
  background-color: #fff;
  display: inline-block;
  min-height: 275px;
  border-color: #CCD4D7;
  border-style: solid;
  border-width: 1px;
  margin: 5px;
  vertical-align: top;
  text-align: left;
  border-radius: 10px;
}

.external-system-registration h1 {
  font-size: 19px;
  color: #ffffff;
  text-align: center;
}

.external-system-registration p {
  font-size: 14px;
  color: #364046;
}

.external-system-registration h5, .external-system-registration li {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .dashboard-alert-h2 {
    margin-top: 5px;
    line-height: 28px;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    font-weight: normal;
    color: #364046;
  }
  .dashboard-info a {
    font-family: Avenir, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 16px;
    color: #478eb3;
    text-decoration: none;
  }
  .dashboard-list a {
    font-family: Avenir, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 16px;
    color: #478eb3;
    text-decoration: none;
  }
  .dashboard-alert a {
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 16px;
    color: #478EB3;
    text-decoration: none;
  }
}
@media only screen and (min-width: 992px) {
  .dashboard-alert-h2 {
    margin-top: 5px;
    line-height: 28px;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    font-weight: normal;
    color: #364046;
  }
  .dashboard-info a {
    font-family: Avenir, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    color: #478eb3;
    text-decoration: none;
  }
  .dashboard-list a {
    font-family: Avenir, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    color: #478eb3;
    text-decoration: none;
  }
  .dashboard-alert a {
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    color: #478EB3;
    text-decoration: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .dashboard-alert-h2 {
    margin-top: 5px;
    line-height: 28px;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    font-weight: normal;
    color: #364046;
  }
  .dashboard-info a {
    font-family: Avenir, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    color: #478eb3;
    text-decoration: none;
  }
  .dashboard-list a {
    font-family: Avenir, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 19px;
    color: #478eb3;
    text-decoration: none;
  }
}
.task-list-summary {
  font-size: 12px;
  font-weight: 400;
  color: #364046;
  vertical-align: bottom;
}

.message-list-summary {
  font-size: 12px;
  font-weight: 400;
  color: #364046;
  vertical-align: bottom;
}

@media (min-width: 1281px) {
  .dashboard-alert-h2 {
    margin-top: 5px;
    line-height: 28px;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-size: 28px;
    font-weight: normal;
    color: #364046;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.sidenav-narrow {
  position: fixed;
  top: 85px;
  left: 0;
  width: 55px;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #023C5B;
}

.sidenav-wide {
  position: fixed;
  top: 85px;
  left: 0;
  width: 230px;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: #023C5B;
}

.sidenav-icon {
  vertical-align: middle;
  font-size: 21px;
}

.sidenav-arrow {
  vertical-align: middle;
  font-size: 14px;
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

.sidenav-arrow-content {
  position: fixed;
  bottom: 50px;
  left: 20px;
}

.sidenav-option {
  height: 55px;
  line-height: 25px;
  padding: 12px 12px 12px 15px;
  vertical-align: middle;
}

.sidenav-option-active {
  height: 55px;
  line-height: 25px;
  border-left-color: #448bb0;
  border-left-style: solid;
  border-left-width: 4px;
  padding: 12px 12px 12px 15px;
  vertical-align: middle;
}

.sidenav-a {
  width: 100%;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  color: #C3C3C3;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.sidenav-option-active .sidenav-a {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bolder;
  color: #ffffff;
  font-size: 14px;
}

.sidenav-a:hover {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  color: #448bb0;
  font-size: 14px;
}

/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.splash-bg {
  width: 100vw;
  height: 100vh;
  display: dark-theme() -ms-flex-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  background-color: #F0F0F0;
}

.splash-bg a {
  font-size: 19px;
  color: #478EB3;
  padding: 0px 30px;
}

.splash-bg a:hover {
  font-size: 19px;
  color: #A8B4BB;
  text-decoration: none;
}

.splash-bg a:active {
  font-size: 19px;
  color: #ffffff;
  text-decoration: none;
}

.splash-body {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.splash-body h1 {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bold;
  font-size: 32px;
  color: #022D44;
  vertical-align: middle;
}

.splash-body h5 {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bold;
  font-size: 32px;
  color: #022D44;
  vertical-align: middle;
}

/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.top-header {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 85px;
  padding: 0;
  background-color: #022D44;
  color: #023C5B;
}

.top-header-body-wide {
  position: fixed;
  top: 0;
  left: 55px;
  width: 100vw;
  height: 85px;
  padding-left: 15px;
  padding-right: 55px;
  background-color: #ffffff;
}

.top-header-body-wide h1 {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bold;
  font-size: 19px;
  color: #023C5B;
}

.top-header-body-narrow {
  position: fixed;
  top: 0;
  left: 230px;
  width: 100vw;
  height: 85px;
  padding-left: 15px;
  padding-right: 230px;
  background-color: #ffffff;
}

.top-header-body-narrow h1 {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bold;
  font-size: 19px;
  color: #023C5B;
}

.top-header-heading {
  float: left;
  text-align: left;
  width: auto;
  position: relative;
  top: 55%;
  left: 0;
  vertical-align: middle;
  z-index: 8000;
  backface-visibility: hidden;
  transform: translate3d(0, -50%, 0) scale(1, 1);
}

.top-header-heading h1 {
  display: inline-block;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bold;
  font-size: 19px;
  color: #023C5B;
  text-align: left;
}

.top-header-heading a {
  display: inline-block;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  font-size: 19px;
  cursor: pointer;
  margin-left: 15px;
}

.top-header-menu {
  float: right;
  display: inline-flex;
  text-align: right;
  width: auto;
  position: relative;
  margin-right: 15px;
  top: 50%;
  left: 0;
  vertical-align: middle;
  backface-visibility: hidden;
  transform: translate3d(0, -50%, 0) scale(1, 1);
}

.top-header-menu a {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  font-size: 19px;
  color: #023C5B;
  margin-left: 15px;
}

.top-header-menu a:hover {
  color: #448bb0;
  text-decoration: none;
}

.top-header-menu a:active {
  font-weight: bolder;
  color: #448bb0;
  text-decoration: none;
}

.top-header-icon-narrow {
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 85px;
  line-height: 85px;
  background-color: #033C5A;
  padding: 0;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: 21px;
  cursor: pointer;
}

.top-header-icon-wide {
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  height: 85px;
  line-height: 85px;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: 21px;
  cursor: pointer;
}

.top-header-icon-content {
  height: 85px;
  line-height: 85px;
  display: block;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 75%;
}

.header-link {
  display: inline;
  font-size: 21px;
  padding: 10px 20px 13px 0px;
  border-radius: 10px;
  cursor: pointer;
}

.header-link.active {
  background-color: #ffffff;
}

.header-link i {
  position: relative;
  padding: 0px 7px;
}

.header-link a {
  position: relative;
  padding-top: 10px;
}

.mat-badge-medium .mat-badge-content {
  width: auto;
  height: 17px;
  line-height: 18px;
  min-width: 18px;
  padding: 0px 5px;
  font-weight: normal;
  font-size: 12px;
  border-radius: 11px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.workspace-wide {
  position: fixed;
  top: 85px;
  left: 55px;
  right: 0px;
  max-height: calc(100vh - 85px);
  height: 100%;
  padding: 25px;
  background-color: #F0F0F0;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0px 0px 0px 50px;
}

.workspace-narrow {
  position: fixed;
  top: 85px;
  left: 230px;
  right: 0px;
  max-height: calc(100vh - 85px);
  height: 100%;
  padding: 15px;
  background-color: #F0F0F0;
  overflow-x: hidden;
  overflow-y: auto;
}

.blue {
  color: #478EB3;
}

.dashboard-icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.task-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.dashboard-header {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
  float: left;
  backface-visibility: hidden;
  white-space: nowrap;
}

.dashboard-header span {
  padding-right: 15px;
  width: 100%;
}

.span-header {
  color: #023C5B;
  font-size: 21px;
}

.section-header {
  margin-bottom: 10px;
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bolder;
  font-size: 21px;
  color: #364046;
}

.inner-column {
  border-color: #023C5B;
  border-style: none solid none none;
  border-width: 2px;
}

.full-width {
  width: 100%;
}

/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.inlineExpanded {
  margin: 7px;
  background-color: #ffffff;
}

.expandedLabel {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  color: #666666;
}

.expandedLabelRight {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  text-align: right;
  font-size: 12px;
  color: #666666;
}

.expandedValue {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bolder;
  font-size: 12px;
  color: #364046;
}

.expandedSubject {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: bolder;
  font-size: 19px;
  color: #364046;
}

.expandedBodyText {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  color: #364046;
}

.pc-click-row:hover td {
  cursor: pointer;
}

.reply {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  position: relative;
  top: -10px;
}

.reply a:hover {
  text-decoration: none;
}

.horizontal-scroll {
  overflow: auto;
}

table.mat-table {
  width: 100%;
}

.mat-paginator-outer-container .mat-paginator-container {
  justify-content: flex-start;
}

mat-paginator.mat-paginator {
  border-top: none;
}

table tr.mat-header-row, table tr.mat-row, table tr.mat-footer-row {
  height: 38px;
}

table th.mat-header-cell {
  color: #212529;
}

table th .mat-header-cell.centered, th .mat-cell.centered {
  justify-content: center;
}

table th.mat-header-cell {
  background-color: #EEF1F4;
  min-height: 25px;
  white-space: nowrap;
  padding: 0px 8px;
}

.mat-row .mat-cell, .mat-footer-cell {
  font-size: 12px;
  overflow: visible;
  padding: 10px 5px !important;
  vertical-align: text-top;
}

.mat-row.disabled .mat-cell {
  color: lightgray;
}

.mat-row.disabled .mat-cell .fa-times:before {
  color: black;
}

.mat-row.disabled .mat-cell .fa-question-circle:before {
  color: black;
}

.mat-table-container {
  position: relative;
}

.mat-table-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-table a {
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: normal;
  font-size: 12px;
  text-decoration: none;
}

.mat-table a .fa {
  font-weight: normal;
  font-size: 12px;
}

tr.mat-row.example-detail-row {
  height: 0;
}

tr.mat-row.example-detail-row td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

tr.mat-row.example-expand-row td {
  border-bottom-width: 0;
}

tr.mat-row.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.mat-row.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.example-element-row td {
  border-top-width: 0;
}

.example-element-detail {
  overflow: hidden;
}

.mat-sort-header-arrow {
  margin: unset !important;
}

td.mat-cell span.table-amount, td.mat-footer-cell span.table-amount {
  display: inline-block;
  min-width: 100px;
  text-align: right;
  padding-right: 20px;
}

table td.mat-cell i.fa {
  font-size: 16px;
  cursor: pointer;
}

th.mat-header-cell.centered {
  text-align: center;
}

th.centered div.mat-sort-header-container {
  justify-content: center;
}

.row-disabled {
  opacity: 0.3;
}

tr.mat-row td.mat-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

table tbody td .indent-sm {
  text-indent: 15px;
}

table tbody td .indent-md {
  text-indent: 30px;
}

.mat-paginator-sticky {
  bottom: -2px;
  position: sticky;
  z-index: 10;
}

/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.tab-row {
  display: flex !important;
  height: 25px;
  line-height: 1em;
  margin: 25px 0 10px 0;
}

.tab-col {
  margin: 15px 0 0 0;
  padding: 0 !important;
}

.tab-body {
  margin: 0;
}

.tab-option {
  display: flex;
  float: left;
  white-space: nowrap;
  align-items: baseline; /* align vertical */
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  color: #478EB3;
  cursor: pointer;
  height: 25px;
  line-height: 1em;
  vertical-align: middle;
  padding-left: 0px !important;
  padding-right: 10px;
  padding-bottom: 15px;
}

.tab-navigation {
  display: flex;
  float: left;
  white-space: nowrap;
  align-items: baseline; /* align vertical */
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  color: #478EB3;
  cursor: pointer;
  height: 25px;
  line-height: 1em;
  vertical-align: middle;
  padding-left: 10px !important;
  padding-right: 10px;
  padding-bottom: 15px;
}

.tab-navigation:first-of-type {
  padding-left: 0px !important;
}

.tab-option:hover {
  font-weight: 600;
  color: #448bb0;
}

.tab-option:active {
  font-weight: 600;
  color: #448bb0;
}

.tab-option-selected {
  display: flex;
  float: left;
  white-space: nowrap;
  align-items: baseline; /* align vertical */
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  height: 25px;
  line-height: 1em;
  vertical-align: middle;
  padding-left: 0px !important;
  padding-right: 10px;
  padding-bottom: 15px;
  text-decoration: 1px #666666 underline;
  text-underline-offset: 6px;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #666666;
}

.tab-navigation-selected {
  display: flex;
  float: left;
  white-space: nowrap;
  align-items: baseline; /* align vertical */
  font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  height: 25px;
  line-height: 1em;
  vertical-align: middle;
  padding-left: 10px !important;
  padding-right: 10px;
  padding-bottom: 0;
  text-decoration: 1px #666666 underline;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #666666;
  text-underline-offset: 6px;
}

.tab-navigation-selected:first-of-type {
  padding-left: 0px !important;
  padding-bottom: 0;
  text-underline-offset: 6px;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: #666666;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .tab-navigation {
    display: flex;
    float: left;
    white-space: inherit;
    align-items: baseline;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    color: #478EB3;
    cursor: pointer;
    height: 25px;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 10px !important;
    padding-right: 10px;
    padding-bottom: 15px;
  }
  .tab-navigation-selected {
    font-size: 14px;
    font-weight: 500;
    white-space: inherit;
    color: #666666;
    text-decoration: 1px #666666 underline;
    text-underline-offset: 4px;
  }
  .tab-option {
    display: flex;
    float: left;
    white-space: inherit;
    align-items: baseline;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    color: #478EB3;
    cursor: pointer;
    height: 25px;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 0px !important;
    padding-right: 10px;
    padding-bottom: 15px;
  }
  .tab-option-selected {
    display: flex;
    float: left;
    white-space: nowrap;
    align-items: baseline;
    font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
    height: 25px;
    line-height: 1em;
    vertical-align: middle;
    padding-left: 0px !important;
    padding-right: 10px;
    padding-bottom: 15px;
    -webkit-text-decoration: 1px #666666 underline;
    text-decoration: 1px #666666 underline;
    text-underline-offset: 4px;
  }
}
/*color styles*/
/*character styles*/
/*menu width*/
/*dashboard dimensions*/
/*legacy link colour definitions*/
body {
  margin: 0px;
  background-color: #023C5B;
}

.logo-login {
  width: 100%;
}

::placeholder {
  color: #666666;
  font-size: 12px;
}

.column-centered {
  display: block;
  margin: auto;
}

.centered {
  text-align: center;
}

.center-fields {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.separator {
  color: #023C5B;
  height: 2px;
  line-height: 2px;
  margin-bottom: 10px;
}

.separator-thick {
  color: #023C5B;
  height: 4px;
  line-height: 4px;
}

.member-type-icon {
  height: 20px;
  position: relative;
  top: -2px;
}

.rotate {
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(-90deg);
}

.overflow-wrap-any {
  overflow-wrap: anywhere;
}

a {
  font-size: 19px;
  font-weight: 500;
  color: #478EB3;
}

a:hover {
  color: #448bb0;
  text-decoration: none;
}

a:focus {
  color: #448bb0;
}

a i {
  font-size: 21px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.dashboard-right {
  margin-right: -15px;
}

.tile-icon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 1.438rem;
  margin-left: -5px;
  color: #478eb3;
}

.onboard-nav {
  min-width: 200px;
}

.left-col {
  min-width: 360px;
  display: block;
}

.icon {
  width: 40px;
  height: 40px;
}

.smallIcon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.notesIcon {
  display: inline-block;
  vertical-align: top;
  width: 5em;
}

.workGadgetIcon {
  display: inline;
  vertical-align: top;
}

.taskName {
  display: inline;
  vertical-align: top;
}

.alignTop {
  vertical-align: top;
}

.inlineBlockTop {
  display: inline-block;
  vertical-align: top;
}

.fontAwesome {
  font-size: 22px;
  color: green;
}

.form-title {
  font-weight: 600;
  color: #023C5B;
  font-size: 21px;
}

.subtitle {
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #d93434;
  margin: auto;
  width: 80%;
}

.error {
  color: #d93434;
}

.priority, .negative-amount, .debit {
  color: #d93434;
}

.fa-group {
  font-size: 19px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #409146;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 15px 24px;
}

.mat-horizontal-stepper-header-container .mat-horizontal-stepper-header, .mat-vertical-stepper-header-container .mat-vertical-stepper-header {
  height: 32px;
}

.mat-vertical-stepper-content .mat-vertical-content {
  padding: 0px 0px 0px 10px;
}

/* Tablet Responsive size */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 0.938rem;
  }
  a {
    font-size: 16px;
  }
  .form-title {
    font-size: 16px;
  }
  .logo-login {
    width: 70%;
  }
}
/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
  .logo-login {
    width: 70%;
  }
  .form-title {
    font-size: 16px;
  }
}
.button-panel {
  margin: 0px auto 0 auto;
  display: block;
}

.button-panel > * {
  display: inline-block;
}

.blue-button {
  min-width: 160px;
  height: 36px;
  background-color: #448bb0;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 9px 9px 9px 9px;
  border: none;
  color: #ffffff;
  margin-left: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  outline: 0px transparent !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.blue-button:disabled {
  background-color: grey;
  opacity: 0.5;
  cursor: default;
}

.blue-button:active:enabled, .blue-button:focus:enabled, .blue-button:hover {
  background-color: #023C5B;
}

button.blue-button.required {
  background-color: #d93434;
}
button.blue-button.required:active, button.blue-button.required:hover, button.blue-button.required:focus {
  background-color: #b90404;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3333333333);
}

.button-link {
  color: #478EB3;
  background-color: transparent;
  cursor: pointer;
  min-width: 160px;
  height: 36px;
  font-size: 12;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 9px 9px 9px 9px;
  border: none;
  margin-left: 10px;
  margin-bottom: 5px;
  white-space: nowrap;
  outline: 0px transparent !important;
}

.button-link:hover {
  background-color: #F8F9F9;
}

.fa {
  padding: 5px;
}

.fa.pull-left {
  position: relative;
  top: 2px;
}

.fa.table-icon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pull-right-button {
  padding-right: 10px;
  margin-top: 4px;
  display: inline-block;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-appearance-standard {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.3);
}

.mat-more-button {
  background: transparent;
  height: 36px;
}
.mat-more-button.mat-button-toggle-appearance-standard {
  color: #448bb0;
  border: none;
}
.mat-more-button button.mat-button-toggle-button .mat-button-toggle-label-content {
  line-height: 36px;
  font-size: 14px;
}

.sc-input {
  padding: 10px 30px 10px 0px;
}

.sc-tooltip {
  font-size: small;
  border-left: 1px solid #023C5B;
}

.sc-order {
  padding: 30px 30px 30px 0px;
}

.sc-order .sales-order {
  width: 100%;
}

.sc-required {
  color: #d93434;
}

.chart-legend {
  display: block !important;
}

.chart-legend .legend-labels {
  text-align: center !important;
  white-space: inherit !important;
}

.chart-legend .legend-label {
  color: black !important;
  padding-left: 5px;
  padding-right: 5px;
}