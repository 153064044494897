@import './constants';


$color-dashboard-alert-bg: $color-1;
$color-dashboard-alert-top-border: $color-9;
$color-dashboard-alert-link: $color-9;
$color-dashboard-alert-value: #364046;
$color-dashboard-alert-label: $color-2;
$color-dashboard-alert-icon: $color-7;
$grid-background-color: rgba(2, 60, 91, .35);
$table-accent-bg: rgba(2, 60, 91, .05) !default;
$table-hover-bg: rgba(2, 60, 91, .075) !default;
$table-active-bg: $table-hover-bg !default;
$grid-border-color: rgba(0, 0, 0, .5);
$grid-border-inner-color: rgba(0, 0, 0, .25);
$border-width: 2px !default;
$border-inner-width: 1px !default;

.dashboard-alert {
    padding: $padding * 1px;
    border-top-width: 4px;
    border-radius: 9px;
    /*border-top-style: solid;
    border-top-color: $color-dashboard-alert-top-border;*/
    background-color: $color-dashboard-alert-bg;
    margin-bottom: 30px;
}

.dashboard-alert a {
    font-family: $font-family;
    font-size: $font-size-dashboard-link;
    color: $color-dashboard-alert-link;
    text-decoration: none;
}

.dashboard-alert-h2 {
    margin: 5px 0px 10px 0px;
    line-height:  $font-size-dashboard-icon;
    font-family: $font-family;
    font-size: $font-size-dashboard-icon;
    font-weight: normal;
    color: $color-dashboard-alert-value;
}

.dashboard-info-main-amount {
    margin: 5px 0px;
    line-height:  $font-size-splash-title;
    font-family: $font-family;
    font-size: $font-size-splash-title;
    font-weight: normal;
    color: $color-dashboard-alert-value;
}

.dashboard-info-sub-amount {
    margin: 5px 0px;
    font-family: $font-family;
    font-size: $character-6 * 1px;
    font-weight: normal;
    color: $color-dashboard-alert-value;
}

.dashboard-alert label {
    font-family: $font-family;
    font-size: $font-size-dashboard-label;
    color: $color-dashboard-alert-label;
}

.dashboard-alert-meta {
     font-family: $font-family;
     font-size: $character-3 * 1px;
     color: $color-7;
}

.dashboard-alert-icon {
    font-family: $font-family;
    font-size: $font-size-dashboard-icon;
    color: $color-dashboard-alert-icon;
}

.dashboard-info {
    padding: 20px 15px;
    border-top-width: 4px;
    border-radius: 9px;
    min-height: 275px;
    background-color: $color-dashboard-alert-bg;
    max-height: calc(100vh - 115px);
    overflow: auto;
}

.dashboard-list {
    padding: $padding * 1px;
    border-top-width: 4px;
    border-radius: 9px;
    background-color: $color-dashboard-alert-bg;
    margin-bottom: 20px;
}

.dashboard-list a {
    font-family: $font-family;
    font-size: $font-size-dashboard-link;
    color: $color-dashboard-alert-link;
    text-decoration: none;
}

.dashboard-list-a:not([href])  {
    font-size: $font-size-blue-button !important;
    font-weight: 400;
    color: $color-dashboard-alert-link;
    text-decoration: none;
    cursor: pointer;
}

.dashboard-list h1 {
    font-family: $font-family;
    font-size: $character-4 * 1px;
    color: $color-dashboard-alert-value;
}

.dashboard-list label {
    position: relative;
    top: -10px;
    font-family: $font-family;
    font-size: $character-4 * 1px;
    color: $color-7;
}

.dashboard-info a, dashboard-link a {
    font-family: $font-family;
    font-size: $font-size-dashboard-link;
    color: $color-dashboard-alert-link;
    text-decoration: none;
}

.dashboard-info a:hover, dashboard-link a:hover {
    cursor: pointer;
}

.dashboard-info h1 {
    font-family: $font-family;
    font-size: $font-size-dashboard-value;
    color: $color-dashboard-alert-value;
}

.dashboard-info label {
    position: relative;
    font-family: $font-family;
    font-size: $font-size-dashboard-label;
    color: $color-dashboard-alert-label;
}

.dashboard-info-icon {
    display: inline-block;
    font-family: $font-family;
    font-size: $font-size-dashboard-icon;
    color: $color-dashboard-alert-icon;
    margin-right:15px
}

.dashboard-list-summary {
    float: right;
    font-family: $font-family;
    font-size: $character-3 * 1px;
    color: $color-7;
    vertical-align: bottom;
    text-align: end;
}

.external-system-registration {
    background-color: #fff;
    display: inline-block;
    min-height: 275px;
    border-color: $color-border-input-box;
    border-style: solid;
    border-width: 1px;
    margin: 5px;
    vertical-align: top;
    text-align: left;
    border-radius: 10px;
}
.external-system-registration h1 {
    font-size: $character-6 * 1px;
    color: $color-1;
    text-align: center;
}

.external-system-registration p {
    font-size: $character-4 * 1px;
    color: $color-10;
}

.external-system-registration h5, .external-system-registration li {
    font-size: $character-4 * 1px;
    font-weight: 400;
    color: $color-7;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .dashboard-alert-h2{
        margin-top: 5px;
        line-height:  $font-size-dashboard-icon;
        font-family: $font-family;
        font-size: $character-6 * 1px;
        font-weight: normal;
        color: $color-dashboard-alert-value;
    }

    .dashboard-info a {
        font-family: Avenir,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,sans-serif!important;
        font-size: $character-5 * 1px;
        color: #478eb3;
        text-decoration: none;
    }

    .dashboard-list a {
        font-family: Avenir,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,sans-serif!important;
        font-size: $character-5 * 1px;
        color: #478eb3;
        text-decoration: none;
    }

    .dashboard-alert a {
        font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
        font-size: $character-5 * 1px;
        color: #478EB3;
        text-decoration: none;
    }
}

@media only screen and (min-width: 992px) {
    .dashboard-alert-h2 {
        margin-top: 5px;
        line-height:  $font-size-dashboard-icon;
        font-family: $font-family;
        font-size: $character-6 * 1px;
        font-weight: normal;
        color: $color-dashboard-alert-value;
    }

    .dashboard-info a {
        font-family: Avenir,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,sans-serif!important;
        font-size: $character-6 * 1px;
        color: #478eb3;
        text-decoration: none;
    }

    .dashboard-list a {
        font-family: Avenir,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,sans-serif!important;
        font-size: $character-6 * 1px;
        color: #478eb3;
        text-decoration: none;
    }

    .dashboard-alert a {
        font-family: "Avenir", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, sans-serif !important;
        font-size: $character-6 * 1px;
        color: #478EB3;
        text-decoration: none;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .dashboard-alert-h2 {
        margin-top: 5px;
        line-height:  $font-size-dashboard-icon;
        font-family: $font-family;
        font-size: $character-6 * 1px;
        font-weight: normal;
        color: $color-dashboard-alert-value;
    }

    .dashboard-info a {
        font-family: Avenir,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,sans-serif!important;
        font-size: $character-6 * 1px;
        color: #478eb3;
        text-decoration: none;
    }

    .dashboard-list a {
        font-family: Avenir,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,sans-serif!important;
        font-size: $character-6 * 1px;
        color: #478eb3;
        text-decoration: none;
    }
}

.task-list-summary {
    font-size: 12px;
    font-weight: 400;
    color: #364046;
    vertical-align: bottom;
}

.message-list-summary {
    font-size: 12px;
    font-weight: 400;
    color: #364046;
    vertical-align: bottom;
}

@media (min-width: 1281px) {
    .dashboard-alert-h2 {
        margin-top: 5px;
        line-height:  $font-size-dashboard-icon;
        font-family: $font-family;
        font-size: $font-size-dashboard-icon;
        font-weight: normal;
        color: $color-dashboard-alert-value;
    }
}
