@import './constants';

$color-form-input: $color-1;
$font-size-input-text: $character-5 * 1px;

a:focus {
    outline: none !important;
}

.title-bold-font {
    font-weight: bold;
}

ol > li.bold-marker::marker {
    font-weight: bold;
}

ol.no-marker, ul.no-marker {
    list-style-type: none;
}

.required {
    color: #d93434;
}
