.sc-input {
    padding: 10px 30px 10px 0px;
}

.sc-tooltip {
    font-size: small;
    border-left: 1px solid #023C5B;
}

.sc-order {
    padding: 30px 30px 30px 0px;
}

.sc-order .sales-order {
    width: 100%;
}

.sc-required {
    color: #d93434;
}