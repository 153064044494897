.chart-legend {
    display: block !important;
}

.chart-legend .legend-labels {
    text-align: center !important;
    white-space: inherit !important;
}

.chart-legend .legend-label {
    color: black  !important;
    padding-left: 5px;
    padding-right: 5px;
}
