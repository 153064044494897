@import './constants';

.splash-bg {
  width: 100vw;
  height: 100vh;
  display: dark-theme() -ms-flex-box;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
  text-align: center;

  background-color: $color-background-light;
}

.splash-bg a {
  font-size: $font-size-splash-link;
  color: $color-link-light-bg;
  padding: 0px 30px;
}

.splash-bg a:hover {
  font-size: $font-size-splash-link;
  color: $color-link-hover-light-bg;
  text-decoration: none;
}

.splash-bg a:active {
  font-size: $font-size-splash-link;
  color: $color-link-active-light-bg;
  text-decoration: none;
}

.splash-body {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

.splash-body h1 {
    font-family: $font-family;
    font-weight: bold;
    font-size: $font-size-splash-title;
    color: $color-12;
    vertical-align: middle;
}

.splash-body h5 {
    font-family: $font-family;
    font-weight: bold;
    font-size: $font-size-splash-title;
    color: $color-12;
    vertical-align: middle;
}
