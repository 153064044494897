@import './constants';

$font-size-table-header: $character-3 * 1px;

.inlineExpanded {
    margin: 7px;
    background-color: $color-1;
}

.expandedLabel {
    font-family: $font-family;
    font-weight: normal;
    font-size: $font-size-table-header;
    color: $color-7;
}

.expandedLabelRight {
    font-family: $font-family;
    font-weight: normal;
    text-align: right;
    font-size: $font-size-table-header;
    color: $color-7;
}

.expandedValue {
    font-family: $font-family;
    font-weight: bolder;
    font-size: $font-size-table-header;
    color: $color-10;
}

.expandedSubject {
    font-family: $font-family;
    font-weight: bolder;
    font-size: $character-6 * 1px;
    color: $color-10;
}

.expandedBodyText {
    font-family: $font-family;
    font-weight: normal;
    font-size: $font-size-table-header;
    color: $color-10;
}

.pc-click-row:hover td {
    cursor: pointer;
}

.reply {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    position: relative;
    top: -10px;
}

.reply a:hover {
    text-decoration: none;
}

.horizontal-scroll {
    overflow: auto;
}

// any new CSS for the material tables below
table.mat-table {
    width: 100%;
}

.mat-paginator-outer-container .mat-paginator-container {
    justify-content: flex-start;
}

mat-paginator.mat-paginator {
    border-top: none;
}

table tr.mat-header-row, table tr.mat-row, table tr.mat-footer-row {
    height: 38px;
}

table th.mat-header-cell {
    color: #212529;
}

table th .mat-header-cell.centered, th .mat-cell.centered {
    justify-content: center;
}

table th.mat-header-cell {
    background-color: #EEF1F4;
    min-height: 25px;
    white-space: nowrap;
    padding: 0px 8px;
}

.mat-row .mat-cell, .mat-footer-cell {
    font-size: 12px;
    overflow: visible;
    padding: 10px 5px !important;
    vertical-align: text-top;
}

.mat-row.disabled .mat-cell {
    color: lightgray;
}

.mat-row.disabled .mat-cell .fa-times:before {
    color: black;
}

.mat-row.disabled .mat-cell .fa-question-circle:before {
    color: black;
}

.mat-table-container {
    position: relative;
}

.mat-table-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mat-table a {
    font-family: $font-family;
    font-weight: normal;
    font-size: $font-size-table-header;
    text-decoration: none;
}

.mat-table a .fa {
    font-weight: normal;
    font-size: $font-size-table-header;
}

tr.mat-row.example-detail-row {
    height: 0;
}

tr.mat-row.example-detail-row td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

tr.mat-row.example-expand-row td {
    border-bottom-width: 0;
}

tr.mat-row.example-element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
}

tr.mat-row.example-element-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-element-row td {
    border-top-width: 0;
}

.example-element-detail {
    overflow: hidden;
}

.mat-sort-header-arrow {
    margin: unset !important;
}

td.mat-cell span.table-amount, td.mat-footer-cell span.table-amount {
    display: inline-block;
    min-width: 100px;
    text-align: right;
    padding-right: 20px;
}

table td.mat-cell i.fa {
    font-size: 16px;
    cursor: pointer;
}

th.mat-header-cell.centered {
    text-align: center;
}

th.centered div.mat-sort-header-container {
    justify-content: center;
}

.row-disabled {
  opacity: 0.3;
}

tr.mat-row td.mat-cell {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

table tbody td .indent-sm {
    text-indent: 15px;
}

table tbody td .indent-md {
    text-indent: 30px;
}

.mat-paginator-sticky {
    bottom: -2px;
    position: sticky;
    z-index: 10;
}
