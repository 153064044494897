/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import "spinner";

@media print {
    .breadcrumb {
        display: none !important;
    }
}

.hidden{
    display: none;
}
.show{
    display: block;
}

#overlay {
    position: absolute;
    top: 0px;
    left:  0px;
    z-index:  99;
    background:  rgba(0,0,0,0.8);
    width:  100%;
    height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clear-both {
    clear: both;
}

.full {
    width: 100% !important;
    max-width: none !important;
}

.break-json-whitespace {
    white-space: break-spaces !important;
}
