@import './constants';

$side-menu-option-height: $menu-size-1 * 1px;
$side-menu-option-line-height: $menu-size-1 - $padding - $padding * 1px;

.sidenav-narrow {
  position: fixed;
  top: $menu-height;
  left: 0;
  width: $menu-narrow-width;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: $color-background-medium;
}

.sidenav-wide {
  position: fixed;
  top: $menu-height;
  left: 0;
  width: $menu-wide-width;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: $color-background-medium;
}

.sidenav-icon {
  vertical-align: middle;
  font-size: $font-size-left-menu-icon;
}

.sidenav-arrow {
  vertical-align: middle;
  font-size: $character-4 * 1px;
  width: 25px!important;
  height: 25px!important;
  cursor: pointer;
}

.sidenav-arrow-content {
  position: fixed;
  bottom: 50px;
  left: 20px;
}

.sidenav-option {
  height: $side-menu-option-height;
  line-height: $side-menu-option-line-height;
  padding: 12px 12px 12px 15px;
  vertical-align: middle;
}

.sidenav-option-active {
  height: $side-menu-option-height;
  line-height: $side-menu-option-line-height;
  border-left-color: $color-8;
  border-left-style: solid;
  border-left-width: 4px;
  padding: 12px 12px 12px 15px;
  vertical-align: middle;
}

.sidenav-a {
  width: 100%;
  font-family: $font-family;
  font-weight: normal;
  color: $color-link-medium;
  font-size: $font-size-left-menu-link;
  cursor: pointer;
  text-decoration: none;
}

.sidenav-option-active .sidenav-a {
  font-family: $font-family;
  font-weight: bolder;
  color: $color-link-medium-active;
  font-size: $font-size-left-menu-link;
}

.sidenav-a:hover {
  font-family: $font-family;
  font-weight: normal;
  color: $color-link-medium-hover;
  font-size: $font-size-left-menu-link;
}
