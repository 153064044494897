@import './constants';

.top-header {
    position: fixed;
    // top: 0;
    left: 0;
    width: 100vw;
    height: $menu-height;
    padding: 0;
    background-color: $color-background-dark;
    color: $color-title-light;
}

.top-header-body-wide {
    position: fixed;
    top: 0;
    left: $menu-narrow-width;
    width: 100vw;
    height: $menu-height;
    padding-left: $padding * 1px;
    padding-right: $menu-narrow-width;
    background-color: $color-background-white;
}

.top-header-body-wide h1 {
    font-family: $font-family;
    font-weight: bold;
    font-size: $font-size-header-title;
    color: $color-title-light;
}

.top-header-body-narrow {
    position: fixed;
    top: 0;
    left: $menu-wide-width;
    width: 100vw;
    height: $menu-height;
    padding-left: $padding * 1px;
    padding-right: $menu-wide-width;
    background-color: $color-background-white;
}

.top-header-body-narrow h1 {
    font-family: $font-family;
    font-weight: bold;
    font-size: $font-size-header-title;
    color: $color-title-light;
}

.top-header-heading {
    float: left;
    text-align: left;
    width: auto;
    position: relative;
    top: 55%;
    left: 0;
    vertical-align: middle;
    z-index: 8000;
    backface-visibility: hidden;
    transform: translate3d(0, -50%, 0) scale(1.0, 1.0);
}

.top-header-heading h1 {
    display: inline-block;
    font-family: $font-family;
    font-weight: bold;
    font-size: $font-size-header-title;
    color: $color-2;
    text-align: left;
}

.top-header-heading a {
    display: inline-block;
    font-family: $font-family;
    font-weight: normal;
    font-size: $font-size-header-menu;
    cursor: pointer;
    margin-left: $padding * 1px;
}

.top-header-menu {
    float: right;
    display: inline-flex;
    text-align: right;
    width: auto;
    position: relative;
    margin-right: $padding * 1px;
    top: 50%;
    left: 0;
    vertical-align: middle;
    backface-visibility: hidden;
    transform: translate3d(0, -50%, 0) scale(1.0, 1.0);
}

.top-header-menu a {
    font-family: $font-family;
    font-weight: normal;
    font-size: $font-size-header-menu;
    color: $color-2;
    margin-left: $padding * 1px;
}

.top-header-menu a:hover {
    color: $color-link-medium-hover;
    text-decoration: none;
}

.top-header-menu a:active {
    font-weight: bolder;
    color: $color-link-medium-hover;
    text-decoration: none;
}

.top-header-icon-narrow {
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: $menu-narrow-width;
    height: $menu-height;
    line-height: $menu-height;
    background-color: $color-background-dark-icon;
    padding: 0;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    font-size: $font-size-left-menu-icon;
    cursor: pointer;
}

.top-header-icon-wide {
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: $menu-wide-width;
    height: $menu-height;
    line-height: $menu-height;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    font-size: $font-size-left-menu-icon;
    cursor: pointer;
}

.top-header-icon-content {
    height: $menu-height;
    line-height: $menu-height;
    display: block;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 75%;
}

.header-link {
    display: inline;
    font-size: $character-7 * 1px;
    padding: 10px 20px 13px 0px;
    border-radius: 10px;
    cursor: pointer;
}

.header-link.active {
    background-color: $color-1;
}

.header-link i {
    position: relative;
    padding: 0px 7px;
}

.header-link a {
    position: relative;
    padding-top: 10px;
}

.mat-badge-medium .mat-badge-content {
    width: auto;
    height: 17px;
    line-height: 18px;
    min-width: 18px;
    padding: 0px 5px;
    font-weight: normal;
    font-size: 12px;
    border-radius: 11px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: -8px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -8px;
}
