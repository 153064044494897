@import './constants';
@import './fields';

$color-form-input-editable: $color-1;
$color-modal-border: $color-14;

/* modal styles */

.modal-close {
    float: right;
    font-size: $character-6 * 1px;
    font-weight: 400;
    color: $color-1;
    cursor: pointer;
    background-color: $color-8;
    border-radius: 50%;
    padding: 2px 12px 5px 12px;
}

.normal-modal {
    width: 700px;
}

.full-modal {
    @media screen and (max-height: 799px) {
        width: 100% !important;
        height: 100% !important;
        max-height: none;
        max-width: none;
        margin-top: 0px;
    }

    @media screen and (min-height: 800px) {
        width: 80%;
        max-width: 80%;
        max-height: 90%;
        margin-top: 20px;
    }
}

i.close {
    cursor: default;
}

.mat-dialog-container {
  padding: 0px!important;
  height: auto !important;
  border-radius: 10px !important;
}

.mat-dialog-container.fixed {
    overflow: hidden;
}

.modal-header {
    display: inherit;
    padding: 20px;
    background-color: #F0F0F0;
}

.modal-content-small {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    margin: 0.625rem auto 1.25rem;
    width: 350px;
    border: none;
}

.modal-content-medium {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    margin: 0.625rem auto 1.25rem;
    width: 500px;
    border: none;
}

.modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    border: none;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    border: none!important;
    padding: 0 20px 20px 20px;
}

.modal-footer-login {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    border: none!important;
    padding: 0 20px 20px 20px;
}

.modal-footer > * {
    margin: 0px;
}

.mat-bottom-sheet-container {
    font: inherit;
}

/* Tablet Responsive size */

@media (min-width: 768px) and (max-width: 991.98px) {
    .modal-header {
        display: inherit;
    }
}


/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
    .modal-content-small {
        width: 100%;
    }

    .modal-header {
        display: inherit;
    }

    .modal-footer-login {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border: none !important;
        padding: 0 20px 20px 20px;
    }

}
