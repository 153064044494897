@import './constants';

$color-form-button-bg: $color-9;
$color-form-button-fg: $color-1;
$font-size-form-button: $character-4 * 1px;
$small-button-width: 96;
$medium-button-width: 195;
$medium-button-height: 40;
$medium-button-line-height: 30;
$small-button-height: 36;

.button-panel {
    margin: 0px auto 0 auto;
    display: block;
}

.button-panel > * {
    display: inline-block;
}

.blue-button {
    min-width: 160px;
    height: $small-button-height * 1px;
    background-color: $color-8;
    cursor: pointer;
    font-size: $font-size-blue-button;
    line-height: 16px;
    font-weight: normal;
    text-transform: uppercase;
    border-radius: 9px 9px 9px 9px;
    border: none;
    color: $color-1;
    margin-left: 10px;
    margin-bottom: 5px;
    white-space: nowrap;
    outline: 0px transparent !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.blue-button:disabled {
    background-color: grey;
    opacity: 0.5;
    cursor: default;
}

.blue-button:active:enabled, .blue-button:focus:enabled, .blue-button:hover {
    background-color: $color-2;
}

button.blue-button.required {
    background-color: #d93434;
    &:active, &:hover, &:focus {
        background-color: #b90404;
        box-shadow: 0 0px 8px #0005;
    }
}

.button-link {
    color: $color-9;
    background-color: transparent;
    cursor: pointer;

    min-width: 160px;
    height: $small-button-height * 1px;
    font-size: $character-3;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;

    border-radius: 9px 9px 9px 9px;
    border: none;
    margin-left: 10px;
    margin-bottom: 5px;
    white-space: nowrap;
    outline: 0px transparent !important;
}

.button-link:hover {
    background-color: $color-3;
}

.fa {
    padding: 5px;
}

.fa.pull-left {
    position: relative;
    top: 2px;
}

.fa.table-icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pull-right-button {
    padding-right: 10px;
    margin-top: 4px;
    display: inline-block;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: #ffff;
    color: rgba(0,0,0,.87);
}

.mat-button-toggle-appearance-standard {
  background-color: #e0e0e0;
  color: rgba(0,0,0,.3);
}

.mat-more-button {
    background: transparent;
    height: 36px;

    &.mat-button-toggle-appearance-standard {
        color: #448bb0;
        border: none;
    }

    button.mat-button-toggle-button .mat-button-toggle-label-content {
        line-height: 36px;
        font-size: 14px;
    }
}
