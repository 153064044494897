@import './constants';
@import './fields';

$color-form-label: $color-2;
$color-form-label-help: $color-7;
$color-form-input-editable: $color-1;

$font-size-form-label: $character-4 * 1px;
$font-size-form-title: $character-7 * 1px;
$font-size-input-text: $character-4 * 1px;
$font-size-input-icon: $character-6 * 1px;
$dashboard-search-height: 40px;
$dashboard-search-width: 250px;
$dashboard-search-input-width: 200px;
$dashboard-search-icon-width: 40px;
$dashboard-search-input-height: 42px;
$input-margin: 10px;
$field-width-default: 300;
$field-width-wide: 600;
$field-width-upload: 300;
$field-width-calendar: 252;
$field-width: 165;
$field-width-narrow: 180;
$field-tel-width-narrow: 50;
$field-tel-width-wide: 100;
$field-width-boolean: 90;
$field-height-default: 35;
$field-width-editable-default: 349;
$field-input-icon-width: 49;
$field-input-icon-height: 40;
$field-radio-diameter: 30;
$date-range-popup-width: 779;
$date-range-popup-height: 366;
$date-picker-button-width: 195;
$date-picker-button-height: 40;


.form-text-area-editable {
    width: $field-width-default * 1px;
    height: (3 * $field-height-default) * 1px;
}

.form-input-editable {
    width: 100%;
    max-width: 500px;
    height: $field-height-default * 1px;
}

.form-control.form-tel-input-narrow {
    display: inline-block;
    width: $field-tel-width-narrow * 1px;
    height: $field-height-default * 1px;
    margin-right: 15px;
}

.form-control.form-tel-input-wide {
    display: inline-block;
    width: $field-tel-width-wide * 1px;
    height: $field-height-default * 1px;
}

.form-input-narrow-editable {
    width: $field-width-narrow * 1px;
    height: $field-height-default * 1px;
}

.form-input-table-header:first-of-type {
    margin-left: 0;
    margin-bottom: 10px;
}

.form-input-table-header {
    display: inline-block;
    width: auto;
    height: $field-height-default * 1px;
    margin-left: 20px;
}

.form-label {
    font-family: $font-family;
    font-weight: 600;
    color: $color-form-label;
    font-size: $font-size-form-label;
    margin: 0px;
}

.form-title {
    font-family: $font-family;
    font-weight: 600;
    color: $color-7;
    font-size: $font-size-form-title;
}

.form-error {
    font-family: $font-family;
    font-weight: normal;
    color: $color-error;
    font-size: $font-size-form-label;
}

.search-text-input {
    display: inline;
    margin: 0 0 0 12px;
    line-height: $dashboard-search-input-height;
    font-family: $font-family;
    background-color: $color-1;
    border-style: none;
    font-size: $font-size-input-text;
    position: absolute;
}

.icon {
    display: inline;
    line-height: $dashboard-search-input-height;
    color: $color-4;
    font-size: $font-size-input-icon;
    margin: 0 10px 0 0;
    padding: 0;
    position: relative;
    top: 50%;
    backface-visibility: hidden;
    transform: translate3d(0, -50%, 0) scale(1.0, 1.0);
}

.search {
    display: inline-block!important;
    width: $dashboard-search-width;
    height: $dashboard-search-height;
    line-height: $dashboard-header-height;
    background-color: $color-1;
    border-radius: 10px 10px 10px 10px;
    border-style: solid;
    border-width: 1px;
    border-color: $color-4;
    padding: 0;
    vertical-align: middle;
}

.checkbox {
    width: 20px;
    height: 20px;
    border-color: $color-4;
    border-style: solid;
    border-width: 1px;
    color: $color-9;
    font-family: serif;
    font-size: $character-2 * 1px;
    margin-left: $padding * 1px;
}

.form-group {
    margin-bottom: 1.25rem;
}

.form-standard {
    float: left;
    clear: both;
    width: 600px;
}

.form-control {
    border-bottom: 1px solid #666666;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    appearance: auto;
    width: 100%;
    max-width: 500px;
}

.form-control-header {
    border-bottom: 1px solid #666666;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    appearance: auto;
    width: 100%;
    max-width: 500px;
    background-color: #fff;
}

.form-control:focus {
    border: none;
    box-shadow: none;
}

input.form-control.ng-dirty:not(form):not(.searcher), textarea.form-control.ng-dirty:not(form), select.form-control.ng-dirty:not(form) {
    border-bottom: 1.5px solid #57ba06 !important;
    box-shadow: none;
}

input.form-control.ng-invalid:not(form):not(.searcher), textarea.form-control.ng-invalid:not(form), select.form-control.ng-invalid:not(form) {
    border-bottom: 1.5px solid #d93434 !important;
    box-shadow: none;
}

input.form-control-header:disabled, textarea.form-control-header:disabled, select.form-control-header:disabled {
    background-color: #e9ecef;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .form-title {
        font-size: $character-5;
    }

    .form-input-table-header {
        display: inline-block;
        width: auto;
        height: 35px;
        margin-left: 0;
    }

}

/* Mobile Responsive size */
@media only screen and (max-width: 600px) {
    .form-input-editable {
        width: 100%;
        height: 35px;
    }

}
